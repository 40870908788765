
import { makeRoute } from '@/router/util.js'

import PageProxy from '@/router/page-proxy'

import HeaderSearchWidget from '@/components/tax-warrant/header-search-widget'
import HeaderSearchWidgetTop from '@/components/tax-warrant/header-search-widget-top'

const TaxWarrantPage = () =>
  import('@/pages/tax-warrant/index.vue'
  /* webpackChunkName: "tax-warrant-index" */)

const AdvancedSearch = () =>
  import('@/pages/tax-warrant/search/advanced.vue'
  /* webpackChunkName: "tax-warrant-advanced-search" */)

const SaveSearch = () =>
  import('@/pages/tax-warrant/search/save.vue'
  /* webpackChunkName: "tax-warrant-save-search" */)

const SearchResults = () =>
  import('@/pages/tax-warrant/search/results.vue'
  /* webpackChunkName: "tax-warrant-search-results" */)

const MySavedPage = () =>
  import('@/pages/tax-warrant/saved.vue'
  /* webpackChunkName: "tax-warrant-saved" */)

export function newRoutes (locale) {
  return [
    makeRoute(locale, '/tax_warrant', { component: TaxWarrantPage }),

    makeRoute(locale, '/tax_warrant/ViewMyTaxWarrants', { component: MySavedPage }),

    makeRoute(locale, '/tax_warrant/TaxWarrantSearch', {
      components: {
        default: PageProxy,
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: {
            'RESULTS-PAGE': SearchResults,
            'SAVE-SEARCH': SaveSearch,
            'GET-SEARCH-FORM': SaveSearch
          }[route.query.action] || AdvancedSearch
        }),
        headerWidget: (route) => ({
          show: route.query.action === 'RESULTS-PAGE'
        }),
        headerWidgetTop: (route) => ({
          show: route.query.action === 'RESULTS-PAGE'
        })
      }
    }),

    makeRoute(locale, '/tax_warrant/TaxWarrantDetails', {
      components: {
        default: () => import('@/pages/tax-warrant/details.vue'),
        headerWidget: HeaderSearchWidget,
        headerWidgetTop: HeaderSearchWidgetTop
      },
      props: {
        default: (route) => ({
          component: import('@/pages/tax-warrant/details.vue')
        }),
        headerWidget: (route) => ({
          show: true
        }),
        headerWidgetTop: (route) => ({
          show: true
        })
      }
    }),

    makeRoute(locale, '/tax_warrant/TaxWarrantSubscription', {
      component: () => import('@/pages/tax-warrant/select-subscription.vue')
    }),

  ]
}
