
import { makeRoute } from '@/router/util.js'

const BartholomewIndex = () =>
  import('@/pages/recorder/manual-indices/bartholomew-manual-index.vue'
    /* webpackChunkName: "recorder-bartholomew-manual" */)

const BrownManualIndex = () =>
  import('@/pages/recorder/manual-indices/brown-manual-index.vue'
    /* webpackChunkName: "recorder-brown-manual" */)

const BooneManualIndex = () =>
  import('@/pages/recorder/manual-indices/boone-manual-index.vue'
    /* webpackChunkName: "recorder-boone-manual" */)

const CarrollManualIndex = () =>
  import('@/pages/recorder/manual-indices/carroll-manual-index.vue'
    /* webpackChunkName: "recorder-carroll-manual" */)

const DaviessManualIndex = () =>
  import('@/pages/recorder/manual-indices/daviess-manual-index.vue'
    /* webpackChunkName: "recorder-daviess-manual" */)

// const DearbornManualIndex = () =>
//   import('@/pages/recorder/manual-indices/dearborn-manual-index.vue'
//     /* webpackChunkName: "recorder-dearborn-manual" */)

const DuboisManualIndex = () =>
  import('@/pages/recorder/manual-indices/dubois-manual-index.vue'
    /* webpackChunkName: "recorder-dubois-manual" */)

const HancockManualIndex = () =>
  import('@/pages/recorder/manual-indices/hancock-manual-index.vue'
    /* webpackChunkName: "recorder-hancock-manual" */)

const KosciuskoManualIndex = () =>
  import('@/pages/recorder/manual-indices/kosciusko-manual-index.vue'
    /* webpackChunkName: "recorder-kosciusko-manual" */)

const MartinManualIndex = () =>
  import('@/pages/recorder/manual-indices/martin-manual-index.vue'
    /* webpackChunkName: "recorder-martin-manual" */)

const MonroeManualIndex = () =>
  import('@/pages/recorder/manual-indices/monroe-manual-index.vue'
    /* webpackChunkName: "recorder-monroe-manual" */)

const MontgomeryManualIndex = () =>
  import('@/pages/recorder/manual-indices/montgomery-manual-index.vue'
    /* webpackChunkName: "recorder-montgomery-manual" */)

const ParkeManualIndex = () =>
  import('@/pages/recorder/manual-indices/parke-manual-index.vue'
    /* webpackChunkName: "recorder-parke-manual" */)

const ShelbyManualIndex = () =>
  import('@/pages/recorder/manual-indices/shelby-manual-index.vue'
    /* webpackChunkName: "recorder-shelby-manual" */)

const VermillionManualIndex = () =>
  import('@/pages/recorder/manual-indices/vermillion-manual-index.vue'
    /* webpackChunkName: "recorder-vermillion-manual" */)

const VigoManualIndex = () =>
  import('@/pages/recorder/manual-indices/vigo-manual-index.vue'
    /* webpackChunkName: "recorder-vigo-manual" */)

const WellsManualIndex = () =>
  import('@/pages/recorder/manual-indices/wells-manual-index.vue'
    /* webpackChunkName: "recorder-wells-manual" */)

export function newRoutes (locale) {
  return [
    makeRoute(locale, '/recorder/ManualIndex/18220', { component: BartholomewIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18208', { component: BrownManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18225', { component: BooneManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18213', { component: CarrollManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18219', { component: DaviessManualIndex }),
    // makeRoute(locale, '/recorder/ManualIndex/18176', { component: DearbornManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18150', { component: DuboisManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18174', { component: HancockManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18172', { component: KosciuskoManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18221', { component: MartinManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18214', { component: MonroeManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18181', { component: MontgomeryManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18196', { component: ParkeManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18136', { component: ShelbyManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18185', { component: VermillionManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18156', { component: VigoManualIndex }),
    makeRoute(locale, '/recorder/ManualIndex/18227', { component: WellsManualIndex })
  ]
}
